import * as React from 'react'
import Layout from '../components/layout'
import ReCAPTCHA from 'react-google-recaptcha'
import { useState } from 'react'
import '../css/contact.css'

const ContactPage = () => {
    const [isVerified, setVerified] = useState(false);

    const onChange = (value) => {
        if (value.length > 0) {
            setVerified(true);
        }
    }

    const onExpired = () => {
        setVerified(false);
    }

    return (
        <Layout pageTitle="Contact" subTitle="Contact Makoto" description="Contact Makoto Nishimura to book a shamisen lesson in Tokyo.">
            <div className='sub-wrapper contact-page'>
                <div className='section-content'>
                    <p>
                        If you would like to contact Makoto to arrange a lesson or to ask a question then please send her a message using the form below.
                    </p>
                    <form className='contact-form' method='post' action='https://getform.io/f/7f8e4666-0fee-4c64-b9fc-3564b4cc58ee'>
                        <div className='form-item'>
                            <label htmlFor='name'>Enter your name:</label>
                            <input type='text' name='name' maxLength='100' placeholder='Name' required />
                        </div>
                        <div className='form-item'>
                            <label htmlFor='email'>Enter your email:</label>
                            <input type='email' name='email' maxLength='100' placeholder='Email' required />
                        </div>
                        <div className='form-item'>
                            <label htmlFor='phone'>Enter your phone number:</label>
                            <input type='tel' name='phone' maxLength='100' placeholder='Phone' />
                        </div>
                        <div className='form-item'>
                            <label htmlFor='message'>Enter a message for Makoto:</label>
                            <textarea type='text' name='message' maxLength='500' placeholder='Enter your message here' rows='20' cols='60' required />
                        </div>
                        <button className='submit-button' type='submit' disabled={!isVerified}>Submit</button>
                        {/* Add ReCaptcha to form */}
                        <ReCAPTCHA
                            className='g-recaptcha'
                            sitekey="6Leszx0gAAAAAKoKT6ze-iGrjD9zjLEfDLq12Wx0"
                            onChange={onChange}
                            onExpired={onExpired}
                            async
                            defer
                        />
                    </form>
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage